import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import SessionTimeout from "./SessionTimeout";

function Header({ setToken }) {
    let history = useHistory();
    const logout = () => {
        setToken("");
        history.push("/");
    };

    let objUser = JSON.parse(sessionStorage.getItem("user"));

    const handleClick = () => {
        setToken("");
        history.push("/");
    };

    return (
        <nav id="mainnav-container">
            <SessionTimeout isAuthenticated={objUser} logOut={handleClick} />
            <div id="mainnav">
                <div id="mainnav-menu-wrap">
                    <div className="nano">
                        <div className="nano-content">
                            <a href="/agent/dashboard.php" className="navbar-brand" style={{ padding: "12px" }}>
                                <img src="/assets/img/vectorDESK_logo_1_inverted.png" style={{ maxHeight: "35px" }} />
                            </a>
                            <div
                                style={{
                                    backgroundColor: "#222",
                                    padding: "10px",
                                    position: "fixed",
                                    bottom: "0px",
                                    width: "218px",
                                }}
                            >
                                <div className="row">
                                    <div className="col-3">
                                        <div className="avatar">
                                            <img src="https://www.pngrepo.com/download/122767/avatar.png" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <span
                                            style={{
                                                fontSize: "1.0em",
                                                color: "#FFF",
                                            }}
                                        >
                                            {objUser.first_name} {objUser.last_name}
                                        </span>
                                        <br />
                                        <span style={{ fontWeight: "100" }}> {objUser.user_level_name} </span>
                                    </div>
                                    <div className="col-3 right">
                                        <a className="link logoutLink" onClick={() => logout()}>
                                            <i className="fas fa-sign-out-alt"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <ul id="mainnav-menu" className="list-group">
                                <li>
                                    <a href="/#/dashboard">
                                        <i className="lefticon fa fa-tachometer-alt"></i>
                                        <span className="menu-title">Dashboard</span>
                                    </a>
                                </li>

                                <li>
                                    <a href="/#/sessions">
                                        <i className="lefticon fa fa-list"></i>
                                        <span className="menu-title">Sessions</span>
                                    </a>
                                </li>
                                {(objUser.user_level == 1 || objUser.user_level == 2) && (
                                    <li>
                                        <a href="/#/roles">
                                            <i className="lefticon fa fa-user-tag"></i>
                                            <span className="menu-title">Roles</span>
                                        </a>
                                    </li>
                                )}
                                {(objUser.user_level == 1 || objUser.user_level == 2) && (
                                    <li>
                                        <a href="/#/sites">
                                            <i className="lefticon fa fa-sitemap"></i>
                                            <span className="menu-title">Sites</span>
                                        </a>
                                    </li>
                                )}
                                {(objUser.user_level == 1 || objUser.user_level == 2) && (
                                    <li>
                                        <a href="/#/users">
                                            <i className="lefticon fa fa-users"></i>
                                            <span className="menu-title">Users</span>
                                        </a>
                                    </li>
                                )}

                                <br />
                                <br />
                                <div id="livePlaceholder"></div>

                                <li className="nohover">
                                    <div style={{ padding: "25px 25px 0px 25px" }}>
                                        <a className="btn btn-primary" href="https://vectortest.planetrocke.com/dev_sample_login.php" target="_blank" rel="noreferrer">
                                            Launch Sample
                                        </a>
                                    </div>
                                </li>
                                <br />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

Header.propTypes = {
    setToken: PropTypes.func.isRequired,
};

export default Header;
