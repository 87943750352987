import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";

export default function Companies() {
    const [companies, setCompanies] = useState(null);

    async function getData() {
        const response = await fetch("https://api-dev.vectordesk.io:5000/api/companies");
        const data = await response.json();
        setCompanies(data);
    }

    useEffect(() => {
        getData();
    }, []);
    return (
        <div className="page">
            <div id="page-head">
                <div id="page-title">
                    <div className="row">
                        <div className="col-md-9">
                            <ol className="breadcrumb">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/#/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Companies</Breadcrumb.Item>
                                </Breadcrumb>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel panel-success panel-bordered">
                <div className="panel-body">
                    <div className="row pageHeader">
                        <div className="col-sm-9">
                            <h4>Companies</h4>
                        </div>

                        <div className="col-sm-3 right">
                            <span className="btn btn-default">
                                <i className="fa fa-plus"></i> Add New Company
                            </span>
                        </div>
                    </div>

                    {companies && (
                        <table width="100%">
                            <thead>
                                <tr>
                                    <th>Company Name</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {companies.map((company) => (
                                    <tr key={company.company_uuid}>
                                        <td>{company.company_name}</td>
                                        <td align="right">&nbsp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
}
