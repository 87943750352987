import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";

export default function Roles() {
    const [roles, setRoles] = useState(null);

    const deleteRole = (id) => {
        Swal.fire({
            title: "Remove this Role?",
            showCancelButton: true,
            confirmButtonText: "Remove",
            confirmButtonColor: "#CC0000",
            showClass: {
                popup: "",
            },
            hideClass: {
                popup: "",
            },
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios
                    .post("https://api-dev.vectordesk.io:5000/api/role/" + id + "/delete", {
                        role_uuid: id,
                    })
                    .then(() => {
                        getData();
                    })
                    .catch(() => {});
            }
        });
    };

    async function getData() {
        const response = await fetch("https://api-dev.vectordesk.io:5000/api/roles");
        const data = await response.json();
        setRoles(data);
    }

    useEffect(() => {
        getData();
    }, []);
    return (
        <div className="page">
            <div id="page-head">
                <div id="page-title">
                    <div className="row">
                        <div className="col-md-9">
                            <ol className="breadcrumb">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/#/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Roles</Breadcrumb.Item>
                                </Breadcrumb>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel panel-success panel-bordered">
                <div className="panel-body">
                    <div className="row pageHeader">
                        <div className="col-sm-9">
                            <h4>Roles</h4>
                        </div>

                        <div className="col-sm-3 right">
                            <a href="/#/role/new" className="btn btn-success">
                                <i className="fa fa-plus"></i> Add New Role
                            </a>
                        </div>
                    </div>

                    {roles && (
                        <table width="100%">
                            <thead>
                                <tr>
                                    <th>Role Name</th>
                                    <th>Agent Can Take Control</th>
                                    <th>Agent Can Request Control</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {roles.map((role) => (
                                    <tr key={role.role_uuid}>
                                        <td>
                                            <a href={"/#/role/" + role.role_uuid}>{role.role_name}</a>
                                        </td>
                                        <td>
                                            <a href={"/#/role/" + role.role_uuid}>{role.agent_can_take_control ? "Yes" : "No"}</a>
                                        </td>
                                        <td>
                                            <a href={"/#/role/" + role.role_uuid}>{role.agent_can_request_control ? "Yes" : "No"}</a>
                                        </td>
                                        <td align="right">
                                            <a className="link" onClick={() => deleteRole(role.role_uuid)}>
                                                <i className="fa fa-trash"></i>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
}
