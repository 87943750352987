import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import { statusLabel, dateDisplay } from "../Util/Util";
const strReplace = require("react-string-replace");

export default function Sessions() {
    let pageSize = 10;
    let objUser = JSON.parse(sessionStorage.getItem("user"));

    const [objSessions, setState] = useState({
        sessions: [],
        totalPages: 0,
        currentPage: 1,
    });

    useEffect(() => {
        // initial list
        processHash();

        async function processHash() {
            let url = window.location.href;
            const [hash, query] = url.split("#")[1].split("?");
            const params = Object.fromEntries(new URLSearchParams(query));
            console.log(hash);
            console.log(params.p);
            if (hash == "/sessions") {
                getData(params.p);
            }
        }

        async function getData(p) {
            console.log("get page " + p);
            let company_uuid;
            if (!p) p = 1;
            if (objUser.user_level == 1) {
                company_uuid = objUser.admin_key;
            } else {
                company_uuid = objUser.company_uuid;
            }
            const response = await fetch("https://api-dev.vectordesk.io:5000/api/sessions/" + company_uuid, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    currentPage: p,
                    pageSize: pageSize,
                }),
            });
            const data = await response.json();
            setState(data);
            console.log(data);
        }

        const handleHashChange = (event) => {
            console.log(event);
            processHash();
        };

        window.addEventListener("hashchange", handleHashChange);

        const handleMessage = (msg) => {
            if (msg.data) {
                console.log("MSG.DATA=", msg.data);
                try {
                    let m = JSON.parse(msg.data);
                    switch (m.event) {
                        case "NEW_SESSION":
                            console.log("NES ESSION!!!");
                            getData();
                            break;
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("hashchange", handleHashChange);
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    return (
        <div className="page">
            <div id="page-head">
                <div id="page-title">
                    <div className="row">
                        <div className="col-md-9">
                            <ol className="breadcrumb">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/#/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Sessions</Breadcrumb.Item>
                                </Breadcrumb>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel panel-success panel-bordered">
                <div className="panel-body">
                    <div className="row pageHeader" style={{ marginBottom: "10px" }}>
                        <div className="col-sm-7">{objSessions.totalSessions ? <b>{objSessions.totalSessions} Sessions</b> : <b>&nbsp;</b>}</div>

                        <div className="col-sm-5 right">
                            <div className="paginationHeader">
                                <div className="row">
                                    <div className="paginationButtonDiv">
                                        {objSessions.currentPage > 1 ? (
                                            <a href={"/#/sessions?p=" + parseInt(parseInt(objSessions.currentPage) - 1)} className="btn btn-xs">
                                                <i className="fa fa-caret-left"></i>
                                            </a>
                                        ) : (
                                            <span className="btn btn-xs btn-disabled">
                                                <i className="fa fa-caret-left"></i>
                                            </span>
                                        )}
                                        {objSessions.totalPages ? (
                                            <span>
                                                {objSessions.currentPage} / {objSessions.totalPages}
                                            </span>
                                        ) : (
                                            <span>&nbsp;</span>
                                        )}
                                        {objSessions.currentPage < objSessions.totalPages ? (
                                            <a href={"/#/sessions?p=" + parseInt(parseInt(objSessions.currentPage) + 1)} className="btn btn-xs">
                                                <i className="fa fa-caret-right"></i>
                                            </a>
                                        ) : (
                                            <span className="btn btn-xs btn-disabled">
                                                <i className="fa fa-caret-right"></i>
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {objSessions.sessions && (
                        <table width="100%" className="sessionsListTable">
                            <thead>
                                <tr>
                                    <th className="center">Action</th>
                                    <th>Session Info</th>
                                    <th>Short Code</th>
                                    <th>Status</th>
                                    <th>Last Active</th>
                                </tr>
                            </thead>
                            <tbody>
                                {objSessions.sessions.map((session) => (
                                    <tr key={session.session_uuid}>
                                        <td className={`sessionListActionColumn ${session.assigned_user_uuid === objUser.user_uuid ? "assignedRow" : ""}`}>
                                            {session.session_status_id === 3 ? (
                                                <a href={"/#/playback/" + session.session_uuid}>
                                                    <button className="btn btn-primary btn-block btnReplay">
                                                        <span>
                                                            <i className="fa fa-play"></i>
                                                        </span>
                                                    </button>
                                                </a>
                                            ) : session.session_status_id === 1 ? (
                                                <a href={"/#/session/" + session.session_uuid}>
                                                    <button className="btn btn-danger btn-block btnLiveView">
                                                        <span>
                                                            <i className="fa fa-chalkboard-teacher"></i>
                                                        </span>
                                                    </button>
                                                </a>
                                            ) : (
                                                <a href={"/#/session/" + session.session_uuid}>
                                                    <button className="btn btn-warning btn-block btnLiveViewInactive">
                                                        <span>
                                                            <i className="fa fa-chalkboard-teacher"></i>
                                                        </span>
                                                    </button>
                                                </a>
                                            )}
                                        </td>
                                        <td className={`${session.assigned_user_uuid === objUser.user_uuid ? "assignedRow" : ""}`}>
                                            <font className="sessionListURL">
                                                {session.site_name}
                                                <span className="sessionListURLDetail">
                                                    &nbsp;&nbsp;<i className="fa fa-caret-right"></i> &nbsp;
                                                    {strReplace(session.latest_url, session.site_domain, () => "")}
                                                </span>
                                                <br />
                                            </font>
                                            <font className="sessionListUA">
                                                {session.hardwarevendor} {session.hardwarename} - {session.platformvendor} {session.platformname} {session.platformversion} - {session.browservendor} {session.browsername} {session.browserversion}
                                                <br />
                                                {session.assigned_user_uuid === objUser.user_uuid && <div className="assignedLabel">ASSIGNED TO ME</div>}
                                                {session.help_requested ? <div>{session.session_status_id === 1 ? <div className="helpRequestedLabel">HELP REQUESTED</div> : <div className="helpRequestedLabel expired">HELP REQUESTED</div>}</div> : <div>&nbsp;</div>}
                                            </font>
                                        </td>
                                        <td className={`${session.assigned_user_uuid === objUser.user_uuid ? "assignedRow" : ""}`}>
                                            <div className="sessionListShortCode">{session.short_code}</div>
                                        </td>
                                        <td className={`${session.assigned_user_uuid === objUser.user_uuid ? "assignedRow" : ""}`}>{statusLabel(session.session_status_id)}</td>
                                        <td className={`${session.assigned_user_uuid === objUser.user_uuid ? "assignedRow" : ""}`}>{dateDisplay(session.modified_at, "m/d/yyyy hh:MM:ss TT")}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
}
