import { React, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { Breadcrumb } from "react-bootstrap";
import { PrismLight as SyntaxHighLighter } from "react-syntax-highlighter";
import docco from "react-syntax-highlighter/dist/esm/styles/hljs/docco";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function Site() {
    const { id } = useParams();
    const history = useHistory();

    const [state, setState] = useState({
        site_uuid: "",
        site_name: "",
        site_domain: "",
        blocked_css_classes: "",
        remote_bar: "",
        remote_stoppable: "",
        help_button: "",
        chat_enabled: "",
        audio_enabled: "",
        clipboard_events_enabled: "",
        video_enabled: "",
        chatwoot_token: "",
        companies: [],
        company_uuid: "",
    });

    const [snippet, setSnippet] = useState({
        code_snippet: "",
        copied: false,
    });

    const [setResult] = useState(null);

    const handleChange = (event) => {
        const { name, value } = event.target;

        setState({
            ...state,
            [name]: value,
        });
    };

    const copiedSnippet = () => {
        setSnippet({
            ...snippet,
            copied: true,
        });
    };

    const saveSite = (event) => {
        event.preventDefault();
        axios
            .post("https://api-dev.vectordesk.io:5000/api/sites/" + id + "/save", {
                ...state,
            })
            .then(() => {
                history.push("/sites");
            })
            .catch(() => {
                setResult({
                    success: false,
                    message: "Something went wrong. Try again later",
                });
            });
    };

    useEffect(() => {
        if (id != "new") {
            getData();
        } else {
            getSeedData();
        }

        async function getSeedData() {
            let response = await fetch("https://api-dev.vectordesk.io:5000/api/companies");
            let arrCompanies = await response.json();
            setState({
                ...state,
                companies: arrCompanies,
                company_uuid: arrCompanies[0].company_uuid,
                site_name: "",
                site_domain: "",
                blocked_css_classes: "",
                remote_bar: 1,
                remote_stoppable: 1,
                help_button: 0,
                chat_enabled: 0,
                audio_enabled: 0,
                clipboard_events_enabled: 0,
                video_enabled: 0,
                chatwoot_token: "",
            });
            setSnippet({
                code_snippet: "none",
            });
        }

        async function getData() {
            let objData;
            let response = await fetch("https://api-dev.vectordesk.io:5000/api/sites/" + id);
            objData = await response.json();
            response = await fetch("https://api-dev.vectordesk.io:5000/api/companies");
            objData.companies = await response.json();
            setState(objData);
            setSnippet({
                code_snippet:
                    `<script>
	(function(d,t) {
		var BASE_URL="https://cb-dev.vectordesk.io";
		var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
		g.src=BASE_URL+"/embed/embed.php?site=` +
                    objData.site_uuid +
                    `";
		g.type="module";
		s.parentNode.insertBefore(g,s);
		g.onload=function(){
			console.log("loaded recording tools");
		}
	})(document,"script");
</script>`,
            });
        }
    }, []);
    return (
        <div className="page">
            <div id="page-head">
                <div id="page-title">
                    <div className="row">
                        <div className="col-md-9">
                            <ol className="breadcrumb">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/#/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/#/sites">Sites</Breadcrumb.Item>
                                    {id == "new" ? <Breadcrumb.Item active>Add New Site</Breadcrumb.Item> : <Breadcrumb.Item active>Edit Site</Breadcrumb.Item>}
                                </Breadcrumb>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel panel-success panel-bordered">
                <div className="panel-body">
                    <br />
                    {state && (
                        <div className="row">
                            <div className="col-sm-4">
                                <form onSubmit={saveSite}>
                                    <div className="form-group">
                                        <label>Site Name*</label>
                                        {state.site_uuid ? <input type="hidden" name="site_uuid" value={state.site_uuid ?? ""} /> : <br />}
                                        <input type="text" className="form-control" name="site_name" value={state.site_name ?? ""} onChange={handleChange} required />
                                    </div>
                                    <div className="form-group">
                                        <label>Company</label>
                                        {state.companies && (
                                            <select className="form-control" name="company_uuid" value={state.company_uuid ?? ""} onChange={handleChange}>
                                                {state.companies.map((company) => (
                                                    <option key={company.company_uuid} value={company.company_uuid}>
                                                        {company.company_name}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label>Site Domain* ( format: &quot;http://...&quot; OR &quot;https://...&quot;)</label>
                                        <input type="text" className="form-control" name="site_domain" value={state.site_domain ?? ""} onChange={handleChange} required />
                                    </div>

                                    <div className="form-group">
                                        <label>Blocked CSS Classes (comma-separated)</label>
                                        <input type="text" className="form-control" name="blocked_css_classes" value={state.blocked_css_classes ?? ""} onChange={handleChange} />
                                    </div>

                                    <div className="form-group">
                                        <label>Show Remote Bar?</label>
                                        <div className="select-wrapper">
                                            <select className="form-control" name="remote_bar" value={state.remote_bar ?? "0"} onChange={handleChange}>
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>Customer Can Stop Remote?</label>
                                        <select className="form-control" name="remote_stoppable" value={state.remote_stoppable ?? "0"} onChange={handleChange}>
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label>Show Help Button?</label>
                                        <select className="form-control" name="help_button" value={state.help_button ?? "0"} onChange={handleChange}>
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label>Chat Enabled?</label>
                                        <select className="form-control" name="chat_enabled" value={state.chat_enabled ?? "0"} onChange={handleChange}>
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label>Audio Enabled?</label>
                                        <select className="form-control" name="audio_enabled" value={state.audio_enabled ?? "0"} onChange={handleChange}>
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label>Clipboard Events Enabled?</label>
                                        <select className="form-control" name="clipboard_events_enabled" value={state.clipboard_events_enabled ?? "0"} onChange={handleChange}>
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label>Video Enabled?</label>
                                        <select className="form-control" name="video_enabled" value={state.video_enabled ?? "0"} onChange={handleChange}>
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label>Chatwoot Token</label>
                                        <input type="text" className="form-control" name="chatwoot_token" value={state.chatwoot_token ?? ""} onChange={handleChange} />
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <br />
                                            <button className="btn btn-success">Save Site</button> &nbsp;
                                            <a className="btn btn-default" href="/#/sites">
                                                Cancel
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-sm-1">&nbsp;</div>
                            <div className="col-sm-7">
                                {id != "new" ? (
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-6">
                                                <label>JavaScript Code Snippet</label>
                                            </div>
                                            <div className="col-6 right">
                                                {snippet.copied ? (
                                                    <span style={{ color: "black" }}>
                                                        <i className="fa fa-check"></i>&nbsp;&nbsp;Snippet copied to clipboard.&nbsp;&nbsp;
                                                    </span>
                                                ) : (
                                                    <span style={{ color: "#777" }}>
                                                        Click to copy&nbsp;<i className="fa fa-caret-right"></i>&nbsp;&nbsp;
                                                    </span>
                                                )}
                                                <CopyToClipboard onCopy={copiedSnippet} text={snippet.code_snippet}>
                                                    <button className="btn btn-primary">
                                                        <i className="fa fa-copy"></i>
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                        Insert the code below just before the closing &lt;/body&gt; tag on your site.
                                        <div className="codeSnippet">
                                            <CopyToClipboard onCopy={copiedSnippet} text={snippet.code_snippet}>
                                                <SyntaxHighLighter language="html" wrapLines="true" theme={docco}>
                                                    {snippet.code_snippet}
                                                </SyntaxHighLighter>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                ) : (
                                    <div>&nbsp;</div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
