import { React, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { Breadcrumb } from "react-bootstrap";

export default function Role() {
    const { id } = useParams();
    const history = useHistory();

    const [state, setState] = useState({
        role_uuid: "",
        role_name: "",
        agent_can_take_control: "",
        agent_can_request_control: "",
    });

    const [setResult] = useState(null);

    const handleChange = (event) => {
        const { name, value } = event.target;

        setState({
            ...state,
            [name]: value,
        });
    };

    const saveRole = (event) => {
        event.preventDefault();
        axios
            .post("https://api-dev.vectordesk.io:5000/api/roles/" + id + "/save", {
                ...state,
            })
            .then(() => {
                history.push("/roles");
            })
            .catch(() => {
                setResult({
                    success: false,
                    message: "Something went wrong. Try again later",
                });
            });
    };

    useEffect(() => {
        if (id != "new") {
            getData();
        } else {
            setState({ roll_uuid: "new" });
        }
        async function getData() {
            const response = await fetch("https://api-dev.vectordesk.io:5000/api/roles/" + id);
            const data = await response.json();
            setState(data);
        }
    }, []);
    return (
        <div className="page">
            <div id="page-head">
                <div id="page-title">
                    <div className="row">
                        <div className="col-md-9">
                            <ol className="breadcrumb">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/#/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/#/roles">Roles</Breadcrumb.Item>
                                    {id == "new" ? <Breadcrumb.Item active>Add New Role</Breadcrumb.Item> : <Breadcrumb.Item active>Edit Role</Breadcrumb.Item>}
                                    <Breadcrumb.Item active>Edit Role</Breadcrumb.Item>
                                </Breadcrumb>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel panel-success panel-bordered">
                <div className="panel-body">
                    <br />
                    {state && (
                        <div className="row">
                            <div className="col-sm-8">
                                <form onSubmit={saveRole}>
                                    <div className="form-group">
                                        <label>Role Name</label>
                                        {state.role_uuid ? <input type="hidden" name="role_uuid" value={state.role_uuid ?? ""} /> : <br />}
                                        <input type="text" className="form-control" name="role_name" value={state.role_name ?? ""} onChange={handleChange} />
                                    </div>

                                    <div className="form-group">
                                        <label>Agent Can Take Control?</label>
                                        <select className="form-control" name="agent_can_take_control" value={state.agent_can_take_control ?? "0"} onChange={handleChange}>
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label>Agent Can Request Control?</label>
                                        <select className="form-control" name="agent_can_request_control" value={state.agent_can_request_control ?? "0"} onChange={handleChange}>
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <br />
                                            <button className="btn btn-success">Save Role</button> &nbsp;
                                            <a className="btn btn-default" href="/#/roles">
                                                Cancel
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
