// standard
import { React, useState, useEffect, Component } from "react";
//import { unmountComponentAtNode } from "react-dom";
import { useLocation } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
// rrweb
import "rrweb-player/dist/style.css";
// jQuery
import jQuery from "jquery";
import moment from "moment";
import axios from "axios";

//import { remove } from "dom-helpers";

let syncitApp;
let session_uuid;
let sessionLoaded = false;

export default function Session(props) {
    const location = useLocation();
    const paths = location.pathname.split("/");
    session_uuid = paths[2];
    let objUser = JSON.parse(sessionStorage.getItem("user"));

    if (!sessionLoaded && syncitApp) {
        console.log("syncitApp", props);
        //        syncitApp.setTransporterSessionUUID(session_uuid);
        //        joinLiveSession();
    }

    //   async function joinLiveSession() {
    //        await loadSessionData();
    //        syncitApp.sendAppReadyForLogin();
    // }

    async function stopRemote() {
        document.getElementById("btnStopRemote").style.display = "none";
        document.getElementById("btnRequestRemote").innerHTML = '<i class="fas fa-people-arrows"></i> &nbsp;Start Cobrowsing';
        //    window.parent.jQuery("#btnRequestRemote").attr("disabled", false);
        document.getElementById("btnRequestRemote").style.display = "inline";
        syncitApp.stopRemoteControlFromApp();
    }

    function updateDetails() {
        axios
            .post("https://api-dev.vectordesk.io:5000/api/sessions/" + session_uuid + "/patch", {
                first_name: objSession.first_name,
                last_name: objSession.last_name,
                email: objSession.email,
                phone: objSession.phone,
                session_notes: objSession.session_notes,
            })
            .then((data) => {
                console.log(data);
            })
            .catch((err) => console.log(err));
    }

    function acceptHelpRequest() {
        let acceptedHelpRequest = { help_requested: false, assigned_user_uuid: objUser.user_uuid };
        axios
            .post("https://api-dev.vectordesk.io:5000/api/sessions/" + session_uuid + "/patch", acceptedHelpRequest)
            .then((data) => {
                console.log("data", data);
                setState({
                    ...objSession,
                    help_requested: false,
                    assigned_user_uuid: objUser.user_uuid,
                });
                console.log("objSession", objSession);
                console.log(acceptedHelpRequest);
            })
            .catch((err) => console.log(err));
    }

    async function requestRemote() {
        console.log("requestRemote");
        // update UI
        document.getElementById("btnRequestRemote").innerHTML = '<div style="margin-left:45px;"><div style="float:left;padding:4px 5px 0px 0px;"><i class="fas fa-spinner fa-spin"></i></div> <div style="float:left;">Requesting...</div></div>';
        //    document.getElementById("btnRequestRemote").disabled = "disabled";

        syncitApp.requestRemoteControlFromApp();
    }

    const [objSession, setState] = useState({
        session_uuid: "",
        user_uuid: "",
        created_at: "",
        modified_at: "",
        latest_url: "",
        devicetype: "",
        deviceid: "",
        ismobile: "",
        screenpixelswidth: 0,
        screenpixelsheight: 0,
        hardwarevendor: "",
        hardwarename: "",
        hardwaremodel: "",
        platformvendor: "",
        platformname: "",
        platformversion: "",
        browservendor: "",
        browsername: "",
        browserversion: "",
        iscrawler: 0,
        remote_enabled: 0,
        site_uuid: "",
        session_notes: "",
        short_code: "",
        chatwoot_conversation_id: 0,
        agent_video: 0,
        customer_video: 0,
        chat_opened: 0,
        session_status_id: 0,
        fingerprint_js_id: "",
        socket_id: "",
        socket_status: "",
        live_session: 0,
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        help_requested: false,
        assigned_user_uuid: "",
    });

    const handleChange = (event) => {
        setState({
            ...objSession,
            [event.target.id]: event.target.value,
        });
    };

    const loadJQEvents = () => {
        // live - resize
        jQuery(window).on("resize", function () {
            var setWidth = jQuery(".sessionViewerMain").width() - jQuery("#viewerRightColumn").width() - 10;
            jQuery("#player").css("width", setWidth);
        });
        // live - set initial size
        var setPlayerWidth = jQuery(".sessionViewerMain").width() - jQuery("#viewerRightColumn").width() - 10;
        jQuery("#player").css("width", setPlayerWidth);

        jQuery(".rr-controller").prependTo(".rr-player__frame");
        //  jQuery('.rr-player').css("width","100%");
        //    jQuery('.rr-player').css("height","auto");
        //        jQuery('.rr-player__frame').css("width","100%");
        //      jQuery('.rr-player__frame').css("height","80%");  };
    };

    useEffect(() => {
        async function getData(blnLoadComponent = true) {
            if (props?.syncitApp) {
                console.log("syncitApp", props?.syncitApp);
                const response = await fetch("https://api-dev.vectordesk.io:5000/api/sessions/" + session_uuid);
                const data = await response.json();
                console.log(data);
                setState(data);
                if (blnLoadComponent) {
                    loadJQEvents();
                    syncitApp = props.syncitApp;
                    syncitApp.setTransporterSessionUUID(session_uuid);
                    syncitApp.loadSession(data);
                    syncitApp.sendAppReadyForLogin();
                }
            }
        }

        getData();

        const handleMessage = (msg) => {
            if (msg.data) {
                console.log("MSG.DATA=", msg.data);
                try {
                    let m = JSON.parse(msg.data);
                    switch (m.event) {
                        case "START_HELP_REQUEST":
                            getData(false);
                            break;
                        case "END_HELP_REQUEST":
                            getData(false);
                            break;
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            if (props?.syncitApp) {
                console.log("IT IS OUT!");
                syncitApp.stopApp();
                sessionLoaded = false;
                session_uuid = "0";
            }
            window.removeEventListener("message", handleMessage);
        };
    }, [props?.syncitApp]);

    return (
        <div>
            {objSession && (
                <div className="page">
                    <div id="page-head">
                        <div id="page-title">
                            <div className="row">
                                <div className="col-md-9">
                                    <ol className="breadcrumb">
                                        <Breadcrumb>
                                            <Breadcrumb.Item href="/#/dashboard">Dashboard</Breadcrumb.Item>
                                            <Breadcrumb.Item href="/#/sessions">Sessions</Breadcrumb.Item>
                                            <Breadcrumb.Item active>Session Viewer</Breadcrumb.Item>
                                        </Breadcrumb>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sessionViewerMain">
                        <div className="d-flex">
                            <div className="sessionViewerPlayerContainer">
                                <div id="player">
                                    <div className="row playerRow">
                                        <div className="col-sm-8">
                                            <div className="sessionInfoHeader">
                                                <b>Started:</b> {moment(objSession.created_at).format("MM/DD/YYYY hh:mm:ssA")}
                                                <br />
                                                <b>Device:</b> {objSession.hardwarevendor} {objSession.hardwarename} ({objSession.platformname} {objSession.platformversion}
                                                )&nbsp;&nbsp; <b>Browser:</b> {objSession.browservendor} {objSession.browsername} {objSession.browserversion}
                                                <br />
                                                <b>Latest URL:</b> <span id="latest_url">{objSession.latest_url.substring(0, 50)}...</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 remoteBarLeft">
                                            <div id="remoteBar11">
                                                <button className="btn btn-sm text-nowrap" onClick={requestRemote} id="btnRequestRemote">
                                                    <div className="btnLoadingWrap">
                                                        <div className="btnLoadingIcon">
                                                            <i className="fas fa-spinner fa-spin"></i>
                                                        </div>{" "}
                                                        <div className="floatLeft">Loading...</div>
                                                    </div>
                                                </button>
                                                <button className="btn btn-sm text-nowrap" onClick={stopRemote} id="btnStopRemote">
                                                    <i className="fas fa-times"></i> &nbsp;Stop Cobrowsing
                                                </button>
                                            </div>
                                        </div>
                                        <div className="liveFrameWrapper" id="liveFrameWrapper"></div>
                                    </div>
                                </div>
                            </div>
                            <div id="viewerRightColumn">
                                {objSession.help_requested ? (
                                    <div className="helpRequestHolder">
                                        <h5>Help Requested</h5>
                                        The user has requested help with this session. Click the button below to accept this request.
                                        <br />
                                        <br />
                                        <button className="btn btn-lg btn-block" onClick={() => acceptHelpRequest()}>
                                            Accept Request
                                        </button>
                                    </div>
                                ) : (
                                    <div className="assignedToHelpHolder">
                                        {objSession.assigned_user_uuid === objUser.user_uuid && (
                                            <div>
                                                <h5>You are Assigned.</h5>
                                                You are assigned as the supporting agent in this session.
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className="rightColumnHeader">
                                    <label>Confirmation Code</label>
                                    <h5>{objSession.short_code}</h5>
                                    <br /> <label>Session Started</label>
                                    <br />
                                    <i className="fa fa-calendar"></i> &nbsp;
                                    {objSession.created_at ?? moment(objSession.created_at).format("MM/DD/YYYY hh:mm:ssA")}
                                    <br />
                                    <br />
                                </div>
                                <div className="rightSectionHeader">Contact Details</div>
                                <div className="rightSectionContentBox">
                                    <div className="row rightSectionContentRow">
                                        <div className="col-6">
                                            <input type="text" className="form-control" id="first_name" placeholder="First Name" value={objSession.first_name || ""} onChange={handleChange} />
                                        </div>
                                        <div className="col-6">
                                            <input type="text" className="form-control" id="last_name" placeholder="Last Name" value={objSession.last_name || ""} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row rightSectionContentRow">
                                        <div className="col-12">
                                            <input type="text" className="form-control" id="email" placeholder="Email Address" value={objSession.email || ""} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row rightSectionContentRow">
                                        <div className="col-12">
                                            <input type="text" className="form-control" id="phone" placeholder="Phone Number" value={objSession.phone || ""} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="sessionViewerSep"></div>
                                <div className="rightSectionHeader">Session Notes</div>
                                <textarea className="form-control" id="session_notes" value={objSession.session_notes || ""} onChange={handleChange}></textarea>
                                <div className="sessionViewerContainer">
                                    <a className="btn btn-block btn-success" id="updateDetails" onClick={() => updateDetails()}>
                                        Update Details
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

Session.propTypes = {
    syncitApp: Component,
};
