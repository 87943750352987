import React from "react";
// CSS
import "./Footer.css";
import metadata from "../../metadata.json";

export default function Footer() {
    return (
        <>
            <footer id="footer">
                <p className="pad-lft">&#0169; VectorDesk</p>
            </footer>

            <div id="live_placeholder"></div>

            <button className="scroll-top btn">
                <i className="pci-chevron chevron-up"></i>
            </button>

            <div className="versionInfo">
                {metadata.buildMajor}.{metadata.buildMinor}.{metadata.buildRevision}.{metadata.buildTag}
            </div>
        </>
    );
}
