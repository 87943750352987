// React
import { React, useEffect, useState } from "react";
import { HashRouter, Route } from "react-router-dom";
// CSS
import "./VectorDesk.css";
// Sub-components
import Dashboard from "../Agent/Dashboard";
import Login from "../Login/Login";
import Playback from "../Agent/Playback";
import Preferences from "../Agent/Preferences";
import Companies from "../Agent/Companies";
import Roles from "../Agent/Roles";
import Role from "../Agent/Role";
import Sessions from "../Agent/Sessions";
import Session from "../Agent/Session";
import Sites from "../Agent/Sites";
import Site from "../Agent/Site";
import Users from "../Agent/Users";
import User from "../Agent/User";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import useToken from "./useToken";
//import { ErrorBoundary } from "react-error-boundary";
// SYNCIT
import { App } from "@syncit/ui";
import "@syncit/ui/dist/style.css";
import "../../assets/css/sync.css";
import { WebSocketTransporter } from "@syncit/transporter";
import Swal from "sweetalert2";
// session timeout

let session_uuid;
let syncitApp;

const VectorDesk = () => {
    const { token, setToken } = useToken();
    const [sync, setSyncitState] = useState([]);
    //  const [response, setResponse] = useState("");

    useEffect(() => {
        async function loadApp() {
            //      console.log("LOADING APP");
            try {
                syncitApp = new App({
                    target: document.getElementById("live_placeholder"),
                    props: {
                        createTransporter() {
                            return new WebSocketTransporter({
                                role: "app",
                                uid: session_uuid,
                                url: "wss://cb-dev.vectordesk.io:8090",
                            });
                        },
                        session_uuid: session_uuid,
                    },
                });
                setSyncitState({ sync: false });
            } catch (error) {
                console.log("ERROR", error);
            }
        }
        loadApp();
        //  syncitApp.sendAppStarted();

        const Toast = (sid, title, msg) =>
            Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                html: "<div style='overflow:hidden!important;'><div class='row'><div class='col-7'>" + title + msg + "</div><div class='col-5 right'><a href=\"/#/session/" + sid + '/live" class="btn btnPlayBanner">View Now</a></div></div></div>',
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
            });

        const handleMessage = (msg) => {
            if (msg.data) {
                try {
                    let m = JSON.parse(msg.data);
                    switch (m.event) {
                        case "NEW_SESSION":
                            console.log("NES ESSION!!!");
                            break;
                        case "START_HELP_REQUEST":
                            Toast(m.session_uuid, "New Support Request", "").fire();
                            break;
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    if (!token) {
        return (
            <HashRouter>
                <Login setToken={setToken} />
                <Footer />
            </HashRouter>
        );
    } else {
        return (
            <HashRouter>
                <div className="wrapper">
                    <Route path="/dashboard">
                        <Header setToken={setToken} />
                        <Dashboard />
                        <Footer />
                    </Route>
                    <Route path="/preferences">
                        <Header setToken={setToken} />
                        <Preferences />
                        <Footer />
                    </Route>
                    <Route path="/companies">
                        <Header setToken={setToken} />
                        <Companies />
                        <Footer />
                    </Route>
                    <Route path="/roles">
                        <Header setToken={setToken} />
                        <Roles />
                        <Footer />
                    </Route>
                    <Route path="/role/:id">
                        <Header setToken={setToken} />
                        <Role />
                        <Footer />
                    </Route>
                    <Route path="/sessions">
                        <Header setToken={setToken} />
                        <Sessions />
                        <Footer />
                    </Route>
                    <Route path="/session/:id">
                        <Header setToken={setToken} />
                        {sync ? <Session syncitApp={syncitApp} /> : "loading"}
                        <Footer />
                    </Route>
                    <Route path="/playback/:id">
                        <Header setToken={setToken} />
                        <Playback />
                        <Footer />
                    </Route>
                    <Route path="/sites">
                        <Header setToken={setToken} />
                        <Sites />
                        <Footer />
                    </Route>
                    <Route path="/site/:id">
                        <Header setToken={setToken} />
                        <Site />
                        <Footer />
                    </Route>
                    <Route path="/users">
                        <Header setToken={setToken} />
                        <Users />
                        <Footer />
                    </Route>
                    <Route path="/user/:id">
                        <Header setToken={setToken} />
                        <User />
                        <Footer />
                    </Route>
                </div>
            </HashRouter>
        );
    }
};

export default VectorDesk;

/*
function setToken(userToken) {
  sessionStorage.setItem("token", JSON.stringify(userToken));
}

function getToken() {
  const tokenString = sessionStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  return userToken?.token;
}
*/
