import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";

export default function Users() {
    const [users, setUsers] = useState(null);
    let objUser = JSON.parse(sessionStorage.getItem("user"));
    console.log(objUser);
    const deleteUser = (id) => {
        Swal.fire({
            title: "Remove this User?",
            showCancelButton: true,
            confirmButtonText: "Remove",
            confirmButtonColor: "#CC0000",
            showClass: {
                popup: "",
            },
            hideClass: {
                popup: "",
            },
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios
                    .post("https://api-dev.vectordesk.io:5000/api/users/" + id + "/delete", {
                        role_uuid: id,
                    })
                    .then(() => {
                        getData();
                    })
                    .catch(() => {});
            }
        });
    };

    async function getData() {
        let strRoute = "";
        if (objUser.user_level != 1) {
            strRoute = "https://api-dev.vectordesk.io:5000/api/companyUsers/" + objUser.company_uuid;
        } else {
            strRoute = "https://api-dev.vectordesk.io:5000/api/users";
        }
        const response = await fetch(strRoute);
        const data = await response.json();
        setUsers(data);
    }

    function userLevelLabel(lvl) {
        let strLevel = "";
        switch (lvl) {
            case 1:
                strLevel = "System Admin";
                break;
            case 2:
                strLevel = "Admin";
                break;
            case 3:
                strLevel = "Agent";
                break;
            default:
                strLevel = "n/a";
                break;
        }
        return strLevel;
    }

    useEffect(() => {
        getData();
    }, []);
    return (
        <div className="page">
            <div id="page-head">
                <div id="page-title">
                    <div className="row">
                        <div className="col-md-9">
                            <ol className="breadcrumb">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/#/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Users</Breadcrumb.Item>
                                </Breadcrumb>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel panel-success panel-bordered">
                <div className="panel-body">
                    <div className="row pageHeader">
                        <div className="col-sm-9">
                            <h4>Users</h4>
                        </div>

                        <div className="col-sm-3 right">
                            <a href="/#/user/new" className="btn btn-success">
                                <i className="fa fa-plus"></i> Add New User
                            </a>
                        </div>
                    </div>

                    {users && (
                        <table width="100%">
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Company</th>
                                    <th>Email</th>
                                    <th>Level</th>
                                    <th>Role</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user) => (
                                    <tr key={user.user_uuid}>
                                        <td>
                                            <a href={"/#/user/" + user.user_uuid}>{user.first_name}</a>
                                        </td>
                                        <td>
                                            <a href={"/#/user/" + user.user_uuid}>{user.last_name}</a>
                                        </td>
                                        <td>
                                            <a href={"/#/user/" + user.user_uuid}>{user.company_name}</a>
                                        </td>
                                        <td>
                                            <a href={"/#/user/" + user.user_uuid}>{user.email}</a>
                                        </td>
                                        <td>
                                            <a href={"/#/user/" + user.user_uuid}>{userLevelLabel(user.user_level)}</a>
                                        </td>
                                        <td>
                                            <a href={"/#/user/" + user.user_uuid}>{user.role_name}</a>
                                        </td>
                                        <td align="right">
                                            <a className="link" onClick={() => deleteUser(user.user_uuid)}>
                                                <i className="fa fa-trash"></i>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
}
