import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";

export default function Sites() {
    const [sites, setSites] = useState(null);

    const deleteSite = (id) => {
        Swal.fire({
            title: "Remove this Site?",
            showCancelButton: true,
            confirmButtonText: "Remove",
            confirmButtonColor: "#CC0000",
            showClass: {
                popup: "",
            },
            hideClass: {
                popup: "",
            },
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios
                    .post("https://api-dev.vectordesk.io:5000/api/sites/" + id + "/delete", {
                        role_uuid: id,
                    })
                    .then(() => {
                        getData();
                    })
                    .catch(() => {});
            }
        });
    };

    async function getData() {
        const response = await fetch("https://api-dev.vectordesk.io:5000/api/sites");
        const data = await response.json();
        setSites(data);
    }

    useEffect(() => {
        getData();
    }, []);
    return (
        <div className="page">
            <div id="page-head">
                <div id="page-title">
                    <div className="row">
                        <div className="col-md-9">
                            <ol className="breadcrumb">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/#/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Sites</Breadcrumb.Item>
                                </Breadcrumb>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel panel-success panel-bordered">
                <div className="panel-body">
                    <div className="row pageHeader">
                        <div className="col-sm-9">
                            <h4>Sites</h4>
                        </div>

                        <div className="col-sm-3 right">
                            <a href="/#/site/new" className="btn btn-success">
                                <i className="fa fa-plus"></i> Add New Site
                            </a>
                        </div>
                    </div>

                    {sites && (
                        <table width="100%">
                            <thead>
                                <tr>
                                    <th>Site Name</th>
                                    <th>Company</th>
                                    <th>Domain</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {sites.map((site) => (
                                    <tr key={site.site_uuid}>
                                        <td>
                                            <a href={"/#/site/" + site.site_uuid}>{site.site_name}</a>
                                        </td>
                                        <td>
                                            <a href={"/#/site/" + site.site_uuid}>{site.company_name}</a>
                                        </td>
                                        <td>
                                            <a href={"/#/site/" + site.site_uuid}>{site.site_domain}</a>
                                        </td>
                                        <td align="right">
                                            <a className="link" onClick={() => deleteSite(site.site_uuid)}>
                                                <i className="fa fa-trash"></i>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
}
