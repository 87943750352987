// standard
import { React, useState, useEffect } from "react";
//import { unmountComponentAtNode } from "react-dom";
import { useLocation } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
// rrweb
import "rrweb-player/dist/style.css";
import rrwebPlayer from "rrweb-player";
// jQuery
import jQuery from "jquery";
import moment from "moment";
import axios from "axios";

//import { remove } from "dom-helpers";

let replayer;
let events = [];
let session_uuid;
let sessionLoaded = false;
let recordingDataLoaded = false;

export default function Playback() {
    const location = useLocation();
    console.log(sessionLoaded);
    const paths = location.pathname.split("/");
    session_uuid = paths[2];

    //    loadSessionData();

    async function loadRecordingData() {
        console.log("loadRecordingData");
        const eventResponse = await fetch("https://vectortest.planetrocke.com/events.php?id=" + session_uuid);
        events = await eventResponse.json();
        recordingDataLoaded = true;

        await fetch("https://api-dev.vectordesk.io:5000/api/sessions/" + session_uuid)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                console.log(data);
                setState(data);
                loadJQEvents();
            });

        replayRecording();
    }

    function updateDetails() {
        axios
            .post("https://api-dev.vectordesk.io:5000/api/sessions/" + session_uuid + "/patch", {
                first_name: objSession.first_name,
                last_name: objSession.last_name,
                email: objSession.email,
                phone: objSession.phone,
                session_notes: objSession.session_notes,
            })
            .then((data) => {
                console.log(data);
            })
            .catch((err) => console.log(err));
    }

    const loadJQEvents = () => {
        // playback - resize
        jQuery(window).on("resize", function () {
            var setWidth = jQuery(".sessionViewerMain").width() - jQuery("#viewerRightColumn").width() - 10;
            jQuery(".rr-player__frame").css("width", setWidth);
        });

        jQuery(".rr-controller").prependTo(".rr-player__frame");
        //  jQuery('.rr-player').css("width","100%");
        //    jQuery('.rr-player').css("height","auto");
        //        jQuery('.rr-player__frame').css("width","100%");
        //      jQuery('.rr-player__frame').css("height","80%");  };
    };

    const [objSession, setState] = useState({
        session_uuid: "",
        user_uuid: "",
        created_at: "",
        modified_at: "",
        latest_url: "",
        devicetype: "",
        deviceid: "",
        ismobile: "",
        screenpixelswidth: 0,
        screenpixelsheight: 0,
        hardwarevendor: "",
        hardwarename: "",
        hardwaremodel: "",
        platformvendor: "",
        platformname: "",
        platformversion: "",
        browservendor: "",
        browsername: "",
        browserversion: "",
        iscrawler: 0,
        remote_enabled: 0,
        site_uuid: "",
        session_notes: "",
        short_code: "",
        chatwoot_conversation_id: 0,
        agent_video: 0,
        customer_video: 0,
        chat_opened: 0,
        session_status_id: 0,
        fingerprint_js_id: "",
        socket_id: "",
        socket_status: "",
        live_session: 0,
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        help_requested: "",
    });

    function replayRecording() {
        console.log("replayRecording");
        replayer = new rrwebPlayer({
            target: document.getElementById("player"), // customizable root element
            props: {
                events,
                width: jQuery(".sessionViewerMain").width() - jQuery("#viewerRightColumn").width() - 10,
            },
        });
        replayer.play();
    }

    const handleChange = (event) => {
        setState({
            ...objSession,
            [event.target.id]: event.target.value,
        });
    };
    useEffect(() => {
        if (!recordingDataLoaded) {
            loadRecordingData();
        }

        return () => {
            sessionLoaded = false;
            session_uuid = "0";
            recordingDataLoaded = false;
            replayer.pause();
            replayer = null;
        };
    }, [session_uuid]);

    return (
        <div>
            {objSession && (
                <div className="page">
                    <div id="page-head">
                        <div id="page-title">
                            <div className="row">
                                <div className="col-md-9">
                                    <ol className="breadcrumb">
                                        <Breadcrumb>
                                            <Breadcrumb.Item href="/#/dashboard">Dashboard</Breadcrumb.Item>
                                            <Breadcrumb.Item href="/#/sessions">Sessions</Breadcrumb.Item>
                                            <Breadcrumb.Item active>Session Viewer</Breadcrumb.Item>
                                        </Breadcrumb>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sessionViewerMain">
                        <div className="d-flex">
                            <div className="sessionViewerPlayerContainer">
                                <div id="player" className="playbackHolder">
                                    <div className="row playerRow">
                                        <div className="col-sm-8">
                                            <div className="sessionInfoHeader">
                                                <b>Started:</b>
                                                {moment(objSession.created_at).format("MM/DD/YYYY hh:mm:ssA")}
                                                <br />
                                                <b>Device:</b> {objSession.hardwarevendor} {objSession.hardwarename} ({objSession.platformname} {objSession.platformversion}
                                                )&nbsp;&nbsp; <b>Browser:</b> {objSession.browservendor} {objSession.browsername} {objSession.browserversion}
                                                <br />
                                                <b>Latest URL:</b> <span id="latest_url">{objSession.latest_url.substring(0, 50)}...</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="viewerRightColumn">
                                {objSession.help_requested ? <div className="helpRequestHolder">Help Requested</div> : <div>&nbsp;</div>}
                                <div className="rightColumnHeader">
                                    <label>Confirmation Code</label>
                                    <h5>{objSession.short_code ? objSession.short_code : <span>&nbsp;</span>}</h5>
                                    <br /> <label>Session Started</label>
                                    <br />
                                    <i className="fa fa-calendar"></i> &nbsp;
                                    {objSession.created_at ? moment(objSession.created_at).format("MM/DD/YYYY hh:mm:ssA") : <span>&nbsp;</span>}
                                    <br />
                                    <br />
                                </div>
                                <div className="rightSectionHeader">Contact Details</div>
                                <div className="rightSectionContentBox">
                                    <div className="row rightSectionContentRow">
                                        <div className="col-6">
                                            <input type="text" className="form-control" id="first_name" placeholder="First Name" value={objSession.first_name || ""} onChange={handleChange} />
                                        </div>
                                        <div className="col-6">
                                            <input type="text" className="form-control" id="last_name" placeholder="Last Name" value={objSession.last_name || ""} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row rightSectionContentRow">
                                        <div className="col-12">
                                            <input type="text" className="form-control" id="email" placeholder="Email Address" value={objSession.email || ""} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row rightSectionContentRow">
                                        <div className="col-12">
                                            <input type="text" className="form-control" id="phone" placeholder="Phone Number" value={objSession.phone || ""} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="sessionViewerSep"></div>
                                <div className="rightSectionHeader">Session Notes</div>
                                <textarea className="form-control" id="session_notes" value={objSession.session_notes || ""} onChange={handleChange}></textarea>
                                <div className="sessionViewerContainer">
                                    <a className="btn btn-block btn-success" id="updateDetails" onClick={() => updateDetails()}>
                                        Update Details
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
