import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import { statusLabel, dateDisplay } from "../Util/Util";

export default function Dashboard() {
    const [objSessions, setState] = useState({
        sessions: [],
        totalPages: 0,
        currentPage: 1,
    });

    useEffect(() => {
        // initial list
        processHash();

        async function processHash() {
            let url = window.location.href;
            const [hash, query] = url.split("#")[1].split("?");
            const params = Object.fromEntries(new URLSearchParams(query));
            console.log(hash);
            console.log(params.p);
            if (hash == "/dashboard") {
                getData();
            }
        }

        async function getData(p) {
            console.log("get page " + p);
            if (!p) p = 1;
            const response = await fetch("https://api-dev.vectordesk.io:5000/api/live_sessions", {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    currentPage: p,
                    pageSize: 100,
                }),
            });
            const data = await response.json();
            setState(data);
            console.log(objSessions);
        }

        const handleMessage = (msg) => {
            if (msg.data) {
                if (msg.data) {
                    try {
                        let m = JSON.parse(msg.data);
                        switch (m.event) {
                            case "NEW_SESSION":
                                getData();
                                break;
                            case "START_HELP_REQUEST":
                                break;
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    return (
        <div className="page">
            <div id="page-head">
                <div id="page-title">
                    <div className="row">
                        <div className="col-md-9">
                            <ol className="breadcrumb">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/#/dashboard">Dashboard</Breadcrumb.Item>
                                </Breadcrumb>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel panel-success panel-bordered">
                <div className="panel-body">
                    <div className="row pageHeader">
                        <div className="col-sm-9">
                            <h4>Live Sessions</h4>
                        </div>

                        <div className="col-sm-3">
                            <div className="paginationHeader"></div>
                        </div>
                    </div>

                    {objSessions.sessions && (
                        <div className="row">
                            {objSessions.sessions.length ? (
                                <div>
                                    {objSessions.sessions.map((session) => (
                                        <div className="col-4 center" key={session.session_uuid}>
                                            <div className="dashboardLiveCell">
                                                {session.session_status_id === 3 ? (
                                                    <a href={"/#/playback/" + session.session_uuid}>
                                                        <button className="btn btn-primary btn-block btnReplay">
                                                            <span>
                                                                <i className="fa fa-play"></i>
                                                            </span>
                                                        </button>
                                                    </a>
                                                ) : session.session_status_id === 1 ? (
                                                    <a href={"/#/session/" + session.session_uuid}>
                                                        <button className="btn btn-danger btn-block btnLiveView">
                                                            <span>
                                                                <i className="fa fa-chalkboard-teacher"></i>
                                                            </span>
                                                        </button>
                                                    </a>
                                                ) : (
                                                    <a href={"/#/session/" + session.session_uuid}>
                                                        <button className="btn btn-warning btn-block btnLiveViewInactive">
                                                            <span>
                                                                <i className="fa fa-chalkboard-teacher"></i>
                                                            </span>
                                                        </button>
                                                    </a>
                                                )}

                                                <br></br>
                                                <br></br>
                                                <h5> {statusLabel(session.session_status_id)}</h5>
                                                {dateDisplay(session.modified_at, "m/d/yyyy hh:MM:ss TT")}
                                                <br />
                                                <br />
                                                <font className="sessionListURL">
                                                    {session.latest_url ? session.latest_url.substring(0, 50) : "Not available"}
                                                    <br />
                                                    <br />
                                                </font>
                                                <font className="sessionListUA">
                                                    {session.hardwarevendor} {session.hardwarename} - {session.platformvendor} {session.platformname} {session.platformversion} - {session.browservendor} {session.browsername} {session.browserversion}
                                                    <br />
                                                    {session.session_uuid}
                                                </font>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div>No live sessions</div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
