import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import SessionTimeoutDialog from "./SessionTimeoutDialog";
import PropTypes from "prop-types";
let countdownInterval;
let timeout;
// 20 minutes (1200 x 1000)
let sessionTimeout = 1200000;
const SessionTimeout = ({ isAuthenticated, logOut }) => {
    const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
    const [timeoutCountdown, setTimeoutCountdown] = useState(0);
    const idleTimer = useRef(null);
    const clearSessionTimeout = () => {
        clearTimeout(timeout);
    };
    const clearSessionInterval = () => {
        clearInterval(countdownInterval);
    };
    const handleLogout = async (isTimedOut = false) => {
        console.log(isTimedOut);
        try {
            setTimeoutModalOpen(false);
            clearSessionInterval();
            clearSessionTimeout();
            logOut();
        } catch (err) {
            console.error(err);
        }
    };
    const handleContinue = () => {
        setTimeoutModalOpen(false);
        clearSessionInterval();
        clearSessionTimeout();
    };
    const onActive = () => {
        if (!timeoutModalOpen) {
            clearSessionInterval();
            clearSessionTimeout();
        }
    };
    const onIdle = () => {
        const delay = 1000 * 1;
        if (isAuthenticated && !timeoutModalOpen) {
            timeout = setTimeout(() => {
                let countDown = 30;
                setTimeoutModalOpen(true);
                setTimeoutCountdown(countDown);
                countdownInterval = setInterval(() => {
                    if (countDown > 0) {
                        setTimeoutCountdown(--countDown);
                    } else {
                        handleLogout(true);
                    }
                }, 1000);
            }, delay);
        }
    };
    return (
        <>
            <IdleTimer ref={idleTimer} onActive={onActive} onIdle={onIdle} debounce={250} timeout={sessionTimeout} />
            <SessionTimeoutDialog countdown={timeoutCountdown} onContinue={handleContinue} onLogout={() => handleLogout(false)} open={timeoutModalOpen} />
        </>
    );
};

SessionTimeout.propTypes = {
    isAuthenticated: PropTypes.any,
    logOut: PropTypes.any,
};

export default SessionTimeout;
