import { React, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { Breadcrumb } from "react-bootstrap";

export default function User() {
    let { id } = useParams();
    const history = useHistory();
    let objUser = JSON.parse(sessionStorage.getItem("user"));

    const [state, setState] = useState({
        user_uuid: "",
        email: "",
        first_name: "",
        last_name: "",
        role_uuid: "",
        company_uuid: "",
        user_level: "",
        password: "",
        roles: [],
        companies: [],
    });

    //  const [setResult] = useState(null);

    const handleChange = (event) => {
        const { name, value } = event.target;

        setState({
            ...state,
            [name]: value,
        });
    };

    function saveUser(event) {
        event.preventDefault();
        console.log(state);
        console.log(id);
        if (id == "new" && !state.password) {
            alert("password is required when user is created");
            return false;
        } else {
            console.log("state", state);
            axios
                .post("https://api-dev.vectordesk.io:5000/api/users/" + id + "/save", {
                    user_uuid: state.user_uuid ?? "",
                    email: state.email,
                    first_name: state.first_name,
                    last_name: state.last_name,
                    role_uuid: state.role_uuid,
                    user_level: state.user_level,
                    password: state.password ?? "",
                    company_uuid: state.company_uuid,
                })
                .then(() => {
                    history.push("/users");
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }

    useEffect(() => {
        if (id != "new") {
            getData();
        } else {
            getRoles().then(function (cool) {
                console.log(cool);
                // got roles, get companies or my current company
                if (objUser.user_level == 1) {
                    // get companies list, set to first
                    getCompanies().then(function (companies) {
                        console.log(companies);
                        setState((prevState) => ({
                            ...prevState,
                            companies: companies,
                            company_uuid: companies[0].company_uuid,
                        }));
                    });
                } else {
                    // set to user's company, they are not super admin
                    setState((prevState) => ({
                        ...prevState,
                        company_uuid: objUser.company_uuid,
                    }));
                }
            });
            // set user level to agent by default
            setState((prevState) => ({
                ...prevState,
                user_level: 3,
            }));
        }

        async function getCompanies() {
            const response = await fetch("https://api-dev.vectordesk.io:5000/api/companies");
            const data = await response.json();
            console.log(data);
            return await Promise.resolve(data);
        }

        async function getRoles() {
            const response = await fetch("https://api-dev.vectordesk.io:5000/api/roles");
            const data = await response.json();
            console.log(data);
            setState((prevState) => ({
                ...prevState,
                roles: data,
                role_uuid: data[0].role_uuid,
            }));
            return await Promise.resolve(state);
        }

        async function getData() {
            let response = await fetch("https://api-dev.vectordesk.io:5000/api/users/" + id);
            let data = await response.json();
            response = await fetch("https://api-dev.vectordesk.io:5000/api/roles");
            let roles = await response.json();
            response = await fetch("https://api-dev.vectordesk.io:5000/api/companies");
            let companies = await response.json();
            setState({
                user_uuid: data[0].user_uuid,
                email: data[0].email,
                first_name: data[0].first_name,
                last_name: data[0].last_name,
                role_uuid: data[0].role_uuid,
                company_uuid: data[0].company_uuid,
                user_level: data[0].user_level,
                roles: roles,
                companies: companies,
                password: "",
            });
            console.log(data);
        }
    }, []);
    return (
        <div className="page">
            <div id="page-head">
                <div id="page-title">
                    <div className="row">
                        <div className="col-md-9">
                            <ol className="breadcrumb">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/#/dashboard">Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/#/users">Users</Breadcrumb.Item>
                                    {id == "new" ? <Breadcrumb.Item active>Add New User</Breadcrumb.Item> : <Breadcrumb.Item active>Edit User</Breadcrumb.Item>}
                                </Breadcrumb>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel panel-success panel-bordered">
                <div className="panel-body">
                    <br />
                    {state && (
                        <div className="row">
                            <div className="col-sm-8">
                                <form onSubmit={saveUser}>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        {state.user_uuid ? <input type="hidden" name="user_uuid" value={state.user_uuid ?? ""} /> : <br />}
                                        <input type="text" className="form-control" name="email" value={state.email ?? ""} onChange={handleChange} />
                                    </div>

                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input type="text" className="form-control" name="first_name" value={state.first_name ?? ""} onChange={handleChange} />
                                    </div>

                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control" name="last_name" value={state.last_name ?? ""} onChange={handleChange} />
                                    </div>

                                    {objUser.user_level == 1 && (
                                        <div className="form-group">
                                            <label>Company</label>
                                            {state.companies && (
                                                <select className="form-control" name="company_uuid" value={state.company_uuid ?? ""} onChange={handleChange}>
                                                    {state.companies.map((company) => (
                                                        <option key={company.company_uuid} value={company.company_uuid}>
                                                            {company.company_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                    )}
                                    <div className="form-group">
                                        <label>Role</label>
                                        {state.roles && (
                                            <select className="form-control" name="role_uuid" value={state.role_uuid ?? ""} onChange={handleChange}>
                                                {state.roles.map((role) => (
                                                    <option key={role.role_uuid} value={role.role_uuid}>
                                                        {role.role_name}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label>User Level</label>
                                        <select className="form-control" name="user_level" value={state.user_level ?? ""} onChange={handleChange}>
                                            <option key="3" value="3">
                                                Agent
                                            </option>
                                            {objUser.user_level == 1 ? (
                                                <option key="2" value="2">
                                                    Admin
                                                </option>
                                            ) : (
                                                <option key="2" value="2" disabled="disabled">
                                                    Admin
                                                </option>
                                            )}
                                            {objUser.user_level == 1 ? (
                                                <option key="1" value="1">
                                                    Super Admin
                                                </option>
                                            ) : (
                                                <option key="1" value="1" disabled="disabled">
                                                    Super Admin
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Password (required on create, ignored if blank on edit)</label>
                                        <input type="text" className="form-control" name="password" onChange={handleChange} />
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <br />
                                            <button className="btn btn-success">Save User</button>
                                            &nbsp;
                                            <a className="btn btn-default" href="/#/users">
                                                Cancel
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
